<template>
  <div class="py-20">
    <!--begin::Form-->
    <form
      class="form col-12 col-md-6 margin-auto fv-plugins-bootstrap5 fv-plugins-framework"
      @submit.prevent.stop="submit()"
    >
      <!--begin::Body-->
      <div class="card-body">
        <KftHeading
          :level="1"
          :title="$t('general.signIn')"
          class="lc-block mt-8 mb-6"
        />
        <Alert
          v-show="showPasswordResetAlert"
          :content="$t('login.passwordResetSentAlertLabel')"
          @close="hidePasswordResetAlert"
        />
        <!--begin::Input group=-->
        <div :class="['col-12 el-form-validation-margin mb-0 mt-5']">
          <label class="required form-label" for="email">{{
            $t("checkout.personalDetails.form.email")
          }}</label>
          <input
            id="email"
            v-model="personalDetails.email"
            :disabled="$wait.any"
            autocomplete="email"
            class="form-control"
            data-test-id="email"
            name="email"
            @keydown.enter.prevent="submit()"
          />
          <span v-if="invalidKeys.includes('email')" class="text-red fs-6">
            {{ errors.email }}
          </span>
        </div>
        <!--end::Input group=-->
        <div class="col-12 el-form-validation-margin mb-0 mt-5">
          <label class="required form-label" for="password">{{
            $t("checkout.personalDetails.form.password")
          }}</label>
          <input
            id="password"
            v-model="personalDetails.password"
            :disabled="$wait.any"
            autocomplete="true"
            class="form-control"
            data-test-id="password"
            name="password"
            type="password"
            @input="clearErrors('password')"
            @keydown.enter.prevent="submit()"
          />

          <span v-if="invalidKeys.includes('password')" class="text-red fs-6">
            {{ errors.password }}
          </span>
        </div>
        <!--end::Input group=-->
        <!--begin::Wrapper-->
        <div
          class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10"
        >
          <div></div>
          <!--begin::Link-->

          <KftLink
            :underline="false"
            type="primary"
            @click:link="$router.push(localePath('/login/forgot-password'))"
            >{{ $t("login.forgotPassword.button") }}
          </KftLink>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->
        <!--begin::Actions-->
        <div class="d-flex flex-stack">
          <!--begin::Submit-->
          <button
            :disabled="$wait.any"
            class="btn btn-primary me-2 flex-shrink-0"
          >
            <!--begin::Indicator label-->
            <span class="indicator-label">{{ $t("general.signIn") }}</span>
            <!--end::Indicator label-->
          </button>
          <!--end::Submit-->
        </div>
        <!--end::Actions-->
      </div>
      <!--begin::Body-->
    </form>
    <!--end::Form-->

    <div v-if="!isBookingSolution" class="col-12 col-md-6 margin-auto">
      <div class="social-login-separator kft-label my-4">
        <span>{{ $t("general.or") }}</span>
      </div>

      <KftSocialLogin />
    </div>
  </div>
</template>
<script>
import { computed, onMounted, ref } from "@vue/composition-api";
import { ssrRef, nextTick } from "@nuxtjs/composition-api";
import { useUserComplete } from "@konfetti/composables";
import VsToast from "@vuesimple/vs-toast";
import { KftHeading, KftLink, Alert } from "@konfetti-ui/vue";
import { useFormValidation, useGtm } from "~/composables";
import { KftSocialLogin } from "~/components";

export default {
  name: "LoginPage",
  components: {
    KftLink,
    Alert,
    KftHeading,
    KftSocialLogin,
  },
  layout: "default-no-app-links",
  setup(
    props,
    {
      root: {
        localePath,
        $i18n,
        $config,
        $wait,
        $router,
        $route,
        error: nuxtError,
      },
    },
  ) {
    const isBookingSolution = computed(() => $config.subdomain);
    if (isBookingSolution?.value) {
      // throw error page
      nuxtError({
        statusCode: 404,
        message: "Page not found",
      });
    }

    const { error, isAuthenticated, getUser, login, user } =
      useUserComplete("general-instance");

    const { sendLogin, sendSignup } = useGtm();

    const personalDetails = ref({
      email: "",
      password: "",
    });

    const rules = ref({
      email: "required|email",
      password: "required|min:6",
    });

    const showPasswordResetAlert = ssrRef(
      $route.query.password_reset_requested === "true",
    );

    const hidePasswordResetAlert = () => {
      showPasswordResetAlert.value = false;
    };

    const { errors, clearErrors, validate, invalidKeys } = useFormValidation(
      personalDetails,
      rules,
    );

    const submit = async () => {
      $wait.start("validating-user");
      await nextTick();

      await login({
        username: personalDetails.value.email,
        password: personalDetails.value.password,
      });

      if (error.value !== null) {
        VsToast.show({
          title: $i18n.t("general.error"),
          position: "bottom-right",
          variant: "error",
          message: error.value?.[0]?.message || $i18n.t("general.error"),
        });

        $wait.end("validating-user");
        await nextTick();
        return false;
      }

      $wait.end("validating-user");
      await nextTick();

      const redirectTo = $route.query.redirectTo;

      $router.push({
        path: localePath("/account/general-info/"),
        query: { redirectTo },
      });
    };

    onMounted(async () => {
      await getUser(true);

      if (isAuthenticated.value) {
        $router.push(localePath("/account/general-info/"));
      }
    });

    return {
      personalDetails,
      rules,
      errors,
      invalidKeys,
      showPasswordResetAlert,
      isBookingSolution,

      clearErrors,
      hidePasswordResetAlert,
      validate,
      submit,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .social-login {
  flex-wrap: wrap;
}
.social-login-separator {
  position: relative;
  text-align: center;
  margin-right: var(--spacer-base);
  color: $description;

  span {
    display: inline-block;
    padding: 0 10px;
    background: $white;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 50%;
    height: 1px;
    background: #ccc;
  }
}
</style>
