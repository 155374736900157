<template>
  <div class="kft-alert">
    <div v-if="content" class="ms-3">
      {{ content }}
    </div>
    <div v-if="closeCard" class="kft-alert__close-button">
      <span @click="$emit('close')">x</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "Alert",
  props: {
    content: {
      type: String,
      default: "",
    },

    closeCard: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.kft-alert {
  display: flex;
  justify-content: space-between;
  border: solid 1px var(--kft-alert-border-color, var(--_c-blue-primary));
  border-radius: 5px;
  background-color: var(--kft-alert-background-color, #edf8f8);
  color: var(--kft-alert-color, $primary);
  padding: var(--spacer-4);
  @include for-mobile {
    margin-top: 10rem;
  }

  &__close-button {
    margin-top: -15px;
    span {
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
}
</style>
